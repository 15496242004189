

body {
  /* display: flex; */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #080008;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal-popup {
  width: 100%;
  position: fixed;
  top: 2px;
  z-index: 99;
  align-items: baseline;
}

.modal-content {
  background: #dab;
  margin-top: 5px;
  width: 400px;
  margin: auto;
  border-radius: 10px;
}

.modal-header {
  display: flex;
  margin-bottom: 16px;
  color: #000;
  justify-content: space-between;
  align-items: center;
  font-size: 30px;
}

.modal-header h3 {
  margin: 5%;
}

.modal-body p { 
 font-size: 22px;
  line-height: 1.5; 
} 

.hidden { 
 display: none; 
}

.close-modal {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  font-size: 40px;
  margin-left: 10px;
  margin: 10px;
}

.close-modal:hover {
  color: #fa5252;
}

.blur-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(5px);
}

 /* Styles for the button */
.moo-button {
  display: inline-block;
  padding: 10px 20px;
  margin: 5px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid #3498db;
  color: #ffffff;
  background-color: #3498db;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Hover effect */
.moo-button:hover {
  background-color: #ffffff;
  color: #3498db;
}

.App {
}

.App-Header {

}